<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on }">
      <v-btn rounded outlined color="success" v-on="on">Create custom domain</v-btn>
    </template>
    <v-card flat>
      <nice-header>Create custom domain</nice-header>
      <v-card-text>
        As an advanced user, you can use a custom domain for your stores. This is currently a manual feature - to enable
        this, please send an email with the details of the domain and store that you want to use to
        <span class="font-weight-bold">chris@microsurveys.app</span> and we'll start the process off
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CustomDomainDialog',
  data: () => ({
    dialog: false,
  })
}
</script>