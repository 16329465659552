<template>
    <v-dialog v-model="dialog" width="600">
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <template v-slot:activator="{ on }">
            <v-btn
                outlined
                rounded
                v-on="on"
                :disabled="disabled"
                color="error"
                class="mx-2"
            >Issue refund</v-btn>
        </template>
        <v-card flat>
            <v-card-title class="primary-title justify-center mb-2">
                    Really issue refund?
                </v-card-title>
                <v-card-text>
                    This order has been cancelled. Do you want to issue a refund to the customer? This action
                    cannot be undone
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        rounded
                        color="error"
                        :loading="loading"
                        @click="issueRefund"
                    >
                        Issue refund
                    </v-btn>
                </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {issueRefund} from '@/graphql'

    export default {
        name: 'IssueRefundButton',
        props: ['order', 'disabled'],
        data: () => ({
            loading: false,
            dialog: false,
            error: false,
            errorMessage: null
        }),
        methods: {
            issueRefund () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: issueRefund,
                    variables: {
                        order: this.order.id
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then((response) => {
                    if (response) {
                        this.loading = false;
                        this.dialog = false;
                        this.$emit('issued')
                    }
                })
            }
        }
    }
</script>
