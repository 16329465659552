<template>
    <div>
        <v-card-text>
            The dashboard shows you a basic overview of your store.
        </v-card-text>

        <v-subheader class="title">Messages</v-subheader>
        <v-divider></v-divider>
        <v-card-text>
            When you first sign up for a Rentify account, you will see a number of messages prompting you
            to complete certain steps in order to properly setup your account. This section will not be
            displayed once you have fully completed the account configuration process.
        </v-card-text>

        <v-subheader class="title">Upcoming bookings</v-subheader>
        <v-divider></v-divider>
        <v-card-text>
            The upcoming bookings widget shows your next 5 bookings, in order of the collection time, from the
            current date.
        </v-card-text>

        <v-subheader class="title">Total sales</v-subheader>
        <v-divider></v-divider>
        <v-card-text>
            The total gross value of all orders placed in your Rentify store.
        </v-card-text>

        <v-subheader class="title">Orders placed</v-subheader>
        <v-divider></v-divider>
        <v-card-text>
            The number of individual orders placed in your Rentify store.
        </v-card-text>

        <v-subheader class="title">Inventory size</v-subheader>
        <v-divider></v-divider>
        <v-card-text>
            The total number of assets available in your inventory
        </v-card-text>

        <v-subheader class="title">Customer count</v-subheader>
        <v-divider></v-divider>
        <v-card-text>
            The number of unique customers (based on their email addresses) who have placed an order in your
            Rentify store.
        </v-card-text>
    </div>
</template>

<script>
    export default {
        name: 'DashboardHelp'
    }
</script>
