<template>
    <v-dialog v-model="dialog" max-width="400">
        <v-snackbar color="error" v-model="error">{{ errorMessage }}</v-snackbar>
        <template v-slot:activator="{ on }">
            <v-btn
                rounded
                outlined
                color="warning"
                v-on="on"
            >Forgot password?</v-btn>
        </template>
        <v-card flat>
            <nice-header>Reset your password</nice-header>
            <v-card-text>
                <v-form v-model="valid">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                rounded
                                outlined
                                label="Email address"
                                v-model="email"
                                :rules="rules"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    rounded
                    color="primary"
                    :loading="loading"
                    @click="resetPassword"
                    :disabled="!valid"
                >
                    Send reset link
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {resetPassword} from '@/graphql'

    export default {
        name: 'ResetPassword',
        data: () => ({
            valid: null,
            dialog: false,
            email: null,
            loading: false,
            rules: [
                v => !!v || 'Please provide your email address',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            error: false,
            errorMessage: null
        }),
        methods: {
            resetPassword () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: resetPassword,
                    variables: {
                        email: this.email
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then((result) => {
                    if (result) {
                        this.loading = false;
                        this.dialog = false;
                        this.$emit('reset')
                    }
                })
            }
        }
    }
</script>
