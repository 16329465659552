<template>
    <div>
        <v-card-text>
            The product details page is split into 4 different sections
        </v-card-text>

        <v-subheader class="title">Overview</v-subheader>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
            The product overview section lets you view and edit basic details about your product. In this section
            you can edit the product <strong>name</strong> or <strong>category</strong> you first specified when
            you created this product. You also have the option to specify a product <strong>description</strong>
            here - this section will be displayed to your customers and allows you to provide more detailed
            information on the product you are renting out. Remember to click the <strong>Save changes</strong>
            button to save any changes you have made to these three fields.
        </v-card-text>

        <v-card-text>
            The overview section also allows you to upload images of your product. These images will be
            visible to your customers. Having a product image greatly increases the number of orders
            you can expect to receive via your Rentify store, so its highly recommended that you include
            at least one per product. To remove an image you uploaded to your product by mistake, simply
            hover the mouse over the image you want to delete until the red bin icon,
            <v-icon dark color="error" small>mdi-delete</v-icon>
            , appears and click on that. Deleting an image
            can not be undone.
        </v-card-text>

        <v-subheader class="title">Pricing</v-subheader>
        <v-divider class="mx-5"></v-divider>
        <v-alert type="info" outlined class="mx-5 my-0" dense>
            All prices will use the same currency defined for your store
        </v-alert>
        <v-card-text>
            The pricing section allows you to <strong>create</strong> or <strong>delete</strong> pricing plans
            for the current product. It is not possible to edit pricing plans. You can have multiple pricing
            plans for a single product - to support hourly, daily, weekly hires. However, you need to set
            at least one pricing plan before your product becomes visible to customers in your Rentify
            store
        </v-card-text>
        <v-card-text>
            To add a new pricing plan, click the <strong>Add new pricing plan</strong> button in the top right
            hand corner to open the form. You'll be prompted to provide three pieces of information:
            <ul>
                <li><strong>Price</strong>: The unit price for this item</li>
                <li><strong>Quantity</strong>: The number of periods this pricing plan covers</li>
                <li><strong>Period</strong>: The hire period</li>
            </ul>
            For example, to create a pricing plan for a product available to hire for £ 50 for 4 hours, set
            the price to <strong>50</strong>, the quantity to <strong>4</strong> and the period to <strong>hour</strong>.
        </v-card-text>

        <v-card-text>
            To delete a pricing plan, simply click the red bin icon,
            <v-icon color="error" small>mdi-delete</v-icon>
            .
            This action cannot be undone.
        </v-card-text>

        <v-subheader class="title">Assets</v-subheader>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
            The assets section shows the catalog of individual instances of the current product that are available to
            hire. In this view you can see the SKU and tags of each item.
        </v-card-text>
        <v-card-text>
            You can edit any item in this view by clicking on the pencil icon, <v-icon color="primary" small>mdi-pencil</v-icon>,
            and updating the form fields accordingly. The <strong>SKU</strong> is issued automatically by Rentify
            at the time of completion, but can be updated if you have your own item identifiers.
        </v-card-text>
        <v-card-text>
            Clicking on the bin icon, <v-icon color="error" small>mdi-delete</v-icon>, will remove the item from your
            inventory, making it unavailable for hire in your store.
        </v-card-text>
        <v-card-text>
            Clicking the <strong>Add more assets</strong> button will allow you to bulk add more assets for a specific
            tag combination.
        </v-card-text>

        <v-subheader class="title">Bookings</v-subheader>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
            This section displays all bookings for the current product
        </v-card-text>
    </div>
</template>

<script>
    export default {
        name: 'ProductDetailsHelp'
    }
</script>
