<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="date"
            rounded
            outlined
            readonly
            clearable
            v-bind="{ ...$attrs, ...$props }"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          :min="min"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
</template>


<script>
    export default {
        name: 'DatePicker',
        props: ['value', 'label', 'rules', 'min'],
        data: () => ({
            menu: null,
        }),
        computed: {
            date: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>
