<template>
    <span>
        <v-snackbar
                top
                v-model="error"
                color="error"
        >
            {{ errorMessage }}
        </v-snackbar>
        <v-icon
                small
                v-if="action"
                @click="handleClick"
                v-bind="{ ...$props, ...$attrs }"
                :disabled="disabled"
        >
            <slot></slot>
        </v-icon>
        <v-btn
                v-else
                :icon="icon"
                outlined
                v-on="on"
                rounded
                :color="color"
                :loading="loading"
                @click="handleClick"
                v-bind="$props"
                :disabled="disabled"
        >
            <slot></slot>
        </v-btn>
    </span>
</template>

<script>
    export default {
        name: 'Btn',
        props: ['color', 'method', 'outlined', 'on', 'icon', 'action', 'xSmall', 'small', 'methodArg', 'disabled'],
        data: () => ({
            loading: false,
            error: false,
            errorMessage: null,
        }),
        methods: {
            handleClick() {
                if (this.method)
                    this.loading = true;
                    this.method(this.methodArg).catch((error) => {
                        this.error = true;
                        this.errorMessage = error.message.replace('GraphQL error: ', '');
                        this.$emit('error', error);
                        this.loading = false
                    }).then((resp) => {
                        if (!this.error) this.$emit('complete', resp);
                        this.loading = false
                    })
                }
            }

    }
</script>
