<template>
  <v-app>
    <navigation-drawer v-model="drawer" v-if="user"/>
    <app-bar :drawer="drawer" @toggle-drawer="drawer = !drawer" :route-name="routeName" v-if="user"/>
    <v-main :class="user ? 'grey lighten-2' : 'primary'">
      <v-container fluid>
        <router-view @refreshUser="refreshLogin"/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { user } from '@/graphql'
import moment from 'moment'

const OPEN_ROUTES = [
  'Login',
  'Register',
  'Set Password',
  'AppSumo',
  'AppSumo Token',
  'LinkSumo',
  'LinkSumo Token',
]

export default {
  name: 'App',
  apollo: {
    user: {
      query: user,
      error() {
        if (OPEN_ROUTES.indexOf(this.$route.name) === -1) {
          this.$router.push('/login')
        }
      }
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    }
  },
  data: () => ({
    drawer: true
  }),
  filters: {
    niceDate(val) {
      return moment(val).calendar()
    }
  },
  methods: {
    refreshLogin() {
      this.$apollo.queries.user.refetch()
    }
  }
};
</script>
