<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
    import { user } from '@/graphql'
    export default {
        name: 'AdminWrapper',
        apollo: { user },
        watch: {
            user () {
                if (this.user) {
                    const { canCreateStores, canEditStore, canAddUsers, canEditBilling } = this.user.profile
                    let allowedRoutes = []
                    if (this.user.profile.admin) {
                        return
                    }
                    if (canCreateStores) allowedRoutes.push('Create new store')
                    if (canEditStore) {
                        allowedRoutes.push('My Store')
                        allowedRoutes.push('Store appearance')
                    }
                    if (canAddUsers) allowedRoutes.push('My Team')
                    if (canEditBilling) {
                        allowedRoutes.push('My Billing')
                        allowedRoutes.push('Coupons')
                    }
                    if (allowedRoutes.indexOf(this.$route.name) === -1) {
                        this.$router.push('/')
                    }
                }
            }
        }

    }
</script>
