<template>
    <v-dialog v-model="dialog" max-width="600">
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <template v-slot:activator="{ on }">
            <v-btn
                    rounded
                    outlined
                    color="primary"
                    v-on="on"
            >
                Add more assets
            </v-btn>
        </template>
        <v-card flat>
            <nice-header>
                Add more assets for {{ product.name }}
            </nice-header>
            <v-card-text class="pl-4">
                The tag fields are all optional
            </v-card-text>
            <v-container>
                <v-form v-model="valid">
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <text-field
                                    label="Quantity"
                                    v-model="form.quantity"
                                    :rules="[rules.required, rules.integer, rules.positive]"
                            >
                            </text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                            <autocomplete
                                :label="store.labelOne"
                                v-model="form.size"
                                :allow-add="true"
                                :items="store.labelOneChoices"
                                item-text="value"
                                item-value="value"
                            >
                            </autocomplete>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                            <autocomplete
                                    :label="store.labelTwo"
                                    item-value="value"
                                    v-model="form.gender"
                                    :items="store.labelTwoChoices"
                                    :allow-add="true"
                                    item-text="value"
                            >

                            </autocomplete>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                            <autocomplete
                                    :label="store.labelThree"
                                    v-model="form.brand"
                                    item-text="value"
                                    :items="store.labelThreeChoices"
                                    :allow-add="true"
                            >
                            </autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined rounded :loading="loading" @click="addAssets">
                    Add assets
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { accountData } from '@/graphql'
    import {addAssets} from '@/graphql'
    import { stores } from '@/graphql'
    import { mapState } from 'vuex'

    export default {
        name: 'AddAssets',
        props: ['product'],
        apollo: { accountData, stores },
        computed: {
            ...mapState(['selectedStore']),
            store () {
                if (this.selectedStore) {
                    return this.stores.find(store => store.hashId === this.selectedStore)
                }
                return null
            }
        },
        data: () => ({
            dialog: false,
            form: {
                brand: null,
                size: null,
                gender: null,
                quantity: null
            },
            loading: false,
            valid: null,
            error: false,
            errorMessage: null,
            rules: {
                required: v => !!v || 'This field is required',
                numeric: v => !isNaN(v) || 'Must be a number',
                integer: v => /^\d+$/.test(v) || 'Must be a whole number',
                positive: v => v > 0 || 'Quantity must be at least 1',
            },
        }),
        methods: {
            addAssets () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: addAssets,
                    variables: {
                        product: this.product.id,
                        quantity: this.form.quantity,
                        size: typeof this.form.size === String ? this.form.size : this.form.size.value,
                        brand: typeof this.form.brand === String ? this.form.brand : this.form.brand.value,
                        gender: typeof this.form.gender === String ? this.form.gender : this.form.gender.value,
                    }
                }).then((response) => {
                    if (response) {
                        this.loading = false;
                        this.$emit('refresh');
                        this.dialog = false
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL errorr', '')
                    this.loading = false
                })
            }
        }
    }
</script>
