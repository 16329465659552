<template>
    <v-navigation-drawer
            v-model="drawer"
            dark
            class="secondary"
            app
    >
        <template v-slot:prepend>
            <v-card flat tile class="secondary">
            <v-card-title class="display-1">Rentify</v-card-title>
        </v-card>
            <v-divider></v-divider>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user.firstName }}</v-list-item-title>
            <v-list-item-subtitle>Logged In</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

        <v-list subheader>
            <v-subheader>
                Operations
            </v-subheader>
            <route-item v-for="route in mainRoutes" :key="route.path" :route="route"></route-item>
            <v-divider class="mb-15"></v-divider>

            <div v-if="showManagementRoutes">
            <v-subheader>
                Management
            </v-subheader>
            <route-item v-for="route in mgmtRoutes" :key="route.path" :route="route"></route-item>
            <v-divider></v-divider>
            </div>
        </v-list>

    </v-navigation-drawer>
</template>

<script>
    import { user } from '@/graphql'
    export default {
        name: 'NavigationDrawer',
        props: ['value'],
        apollo: { user },
        data: () => ({
            mainRoutes: [
                {
                    path: '/',
                    name: 'Dashboard',
                    icon: 'mdi-apps',
                },
                {
                    path: '/inventory',
                    name: 'Inventory',
                    icon: 'mdi-clipboard-list-outline',
                },
                {
                    path: '/orders',
                    name: 'Orders',
                    icon: 'mdi-clipboard-text-multiple',
                    showNewOrders: true
                }, {
                    path: '/schedule',
                    name: 'Schedule',
                    icon: 'mdi-calendar',
                }, {
                    path: '/enquiries',
                    name: 'Enquiries',
                    icon: 'mdi-message-text',
                }
            ]
        }),
        computed: {
            showManagementRoutes () {
                if (this.user) {
                    const { admin, canCreateStores, canEditStore, canAddUsers, canEditBilling } = this.user.profile
                    return [admin, canCreateStores, canEditStore, canAddUsers, canEditBilling].some(obj => !!obj)
                }
                return false
            },
            mgmtRoutes () {
                let routes = []
                if (this.user) {
                    const { admin, canCreateStores, canEditStore, canAddUsers, canEditBilling } = this.user.profile
                    if (admin) routes.push({
                        icon: 'mdi-cog-outline',
                        path: '/account',
                        name: 'My Account'
                    })
                    if (canEditStore) {
                        routes.push({
                            path: '/store',
                            name: 'My Store',
                            icon: 'mdi-store',
                        })
                        routes.push({
                            path: '/theme',
                            name: 'Store appearance',
                            icon: 'mdi-palette',
                        })
                    }
                    if (canEditBilling) {
                        routes.push({
                            path: '/billing',
                            name: 'My Billing',
                            icon: 'mdi-credit-card',
                        })
                        routes.push({
                            path: '/coupons',
                            name: 'Coupons',
                            icon: 'mdi-ticket-percent',
                        })
                    }
                    if (canAddUsers) routes.push({
                        path: '/users',
                        name: 'My Team',
                        icon: 'mdi-account-multiple-plus',
                    })
                    if (canCreateStores) routes.push({
                        path: '/newstore',
                        name: 'Create new store',
                        icon: 'mdi-plus',
                    })
                }
                return routes
            },
            drawer: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val)
                }
            }
        }
    }
</script>
