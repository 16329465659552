<template>
    <modal
        btn-text="Add new pricing plan"
        :title="`Add new pricing plan for ${product.name}`"
        v-model="modal"
        width="700"
    >
        <v-form v-model="valid">
            <v-card-text class="py-0">
                  <v-row>
                      <v-col cols="12" md="4">
                          <text-field
                            label="Price"
                            :prefix="product.catalog.currency.symbol"
                            v-model="form.price"
                            :rules="[rules.required, rules.float, rules.price]"
                          ></text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                          <text-field
                            label="Interval"
                            v-model="form.interval"
                            :rules="[rules.required, rules.integer]"
                          ></text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                          <autocomplete
                            label="Period"
                            v-model="form.period"
                            :items="periods"
                        ></autocomplete>
                      </v-col>
                  </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <btn color="primary" :disabled="!valid" :method="createPlan" @complete="complete">Create plan</btn>
            </v-card-actions>
        </v-form>
    </modal>
</template>

<script>
    import {createPlan} from '@/graphql'

    var countDecimals = function(value) {
        if (value) {
            if (Math.floor(value) !== value && value.toString().includes('.')) {
                return value.toString().split(".")[1].length || 0;
            }
        }
        return 2
    };

    export default {
        name: 'PricingPlanForm',
        props: ['product'],
        data: () => ({
            valid: null,
            modal: null,
            form: {
                price: '0.00',
                interval: 1,
                period: 'hour'
            },
            currencies: [
                { value: 'gbp', text: 'GBP (£)'}
            ],
            periods: [
                { value: 'hour', text: 'Hour'},
                { value: 'day', text: 'Day'},
                { value: 'week', text: 'Week'},
            ],
            rules: {
                required: v => !!v || v === 0 || 'This field is required',
                float: v => !isNaN(parseFloat(v)) || 'Must be a number',
                integer: v => /^\d+$/.test(v) || 'Must be a whole number',
                price: v => countDecimals(v) === 2|| 'Invalid price'
            }
        }),
        methods: {
            createPlan () {
                return this.$apollo.mutate({
                    mutation: createPlan,
                    variables: {
                        product: this.$route.params.id,
                        ...this.form
                    }
                })
            },
            complete () {
                this.modal = false;
                this.$emit('refresh')
            }
        }
    }
</script>
