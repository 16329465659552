<template>
    <auth-wrapper>
        <v-snackbar color="success" v-model="showResetConfirm">Please check your email for a password reset link</v-snackbar>
        <v-card-title primary-title class="justify-center mb-5">Login to Rentify</v-card-title>

        <v-card-text class="body-2 justify-center text-center">
            Enter your credentials below to continue
        </v-card-text>
        <v-card-text class="mb-0 pb-0">
            <v-form v-model="valid" autocomplete="off">
                <text-field
                        label="Email"
                        name="login"
                        prepend-icon="mdi-email"
                        :rules="[rules.required]"
                        v-model="form.username"
                ></text-field>

                <text-field
                        label="Password"
                        :type="showPass ? 'text' : 'password'"
                        prepend-icon="mdi-lock"
                        :rules="[rules.required]"
                        v-model="form.password"
                ></text-field>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <reset-password @reset="showResetConfirm = true"/>
            <v-spacer></v-spacer>
            <btn
                    color="primary"
                    :disabled="!valid"
                    :method="login"
                    @complete="handleLogin"
            >Login
            </btn>
        </v-card-actions>

        <v-card-text class="body-2 mt-5 justify-center text-center">
            Don't have an account yet?
            <router-link
              to="/register"
              v-slot="{ href }"
            >
            <a :href="href" style="text-decoration: none">Click here</a>
            </router-link> to create one
        </v-card-text>
    </auth-wrapper>
</template>

<script>
    import { user, login } from '@/graphql'
    import Cookie from 'js-cookie'
    import {BACKEND_DOMAIN} from '@/variables.js';

    export default {
        name: 'LoginScreen',
        apollo: { user },
        data: () => ({
            form: {
                username: null,
                password: null,
            },
            valid: null,
            showPass: false,
            showResetConfirm: false,
            rules: {
                required: value => !!value || 'This field is required',
            }
        }),
        methods: {
            login() {
                const variables = this.form;
                return this.$apollo.mutate({
                    mutation: login,
                    variables
                })
            },
            handleLogin({data}) {
                const {token} = data.login;
                if (BACKEND_DOMAIN) {
                    Cookie.set('JWT', token, {domain: BACKEND_DOMAIN});

                } else {
                    Cookie.set('JWT', token);

                }
                window.location.reload()
            }
        }
    }

</script>
