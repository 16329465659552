import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  state: {
    selectedStore: null
  },
  mutations: {
    setStore (state, { store }) {
      state.selectedStore = store
    }
  }


})

export default store
