<template>
    <v-dialog v-model="dialog" max-width="600">
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <template v-slot:activator="{ on }">
            <v-btn
                color="error"
                rounded
                outlined
                v-on="on"
                :disabled="disabled"
            >Retain deposit</v-btn>
        </template>
        <v-card flat>
            <nice-header>Retain deposit</nice-header>
            <v-card-text class="pb-0">
                <v-alert type="error">
                    Warning - you are about to claim all or part of the security deposit currently held on your customer's
                    account. This option must only be used in case rental assets have not been returned, or have been
                    returned damaged. This action can only be done once
                </v-alert>

                <v-form v-model="valid">
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <v-text-field
                                outlined
                                rounded
                                label="Amount to retain"
                                v-model="amount"
                                :prefix="order.currency.symbol"
                                :rules="rules"
                                :error-messages="errors"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                            <v-textarea
                                auto-grow
                                outlined
                                rounded
                                label="Reason for retention"
                                v-model="reason"
                                :rules="reasonRules"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    rounded
                    outlined
                    color="error"
                    :loading="loading"
                    :disabled="!valid"
                    @click="capture"
                >
                    Retain deposit
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
    import {captureDeposit} from '@/graphql'

    export default {
        name: 'RetainDeposit',
        props: ['disabled', 'order'],
        data: () => ({
            dialog: false,
            valid: null,
            loading: false,
            error: false,
            errorMessage: null,
            amount: 0,
            reason: null,
            rules: [
                v => !!v || 'Please enter a value greater than zero',
                v => !isNaN(v) || 'Invalid value',
                v => v > 0 || 'Please enter a value greater than zero',
            ],
            reasonRules: [
                v => !!v || 'You must provide a reason',
            ]
        }),
        computed: {
            errors () {
                let errors = [];
                if (this.amount > this.order.deposit.amount) {
                    errors.push('Cannot claim more than the deposit amount')
                }
                return errors
            }
        },
        mounted () {
            this.amount = this.order.deposit.amount
        },
        methods: {
            capture () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: captureDeposit,
                    variables: {
                        amount: this.amount,
                        order: this.order.hashId,
                        reason: this.reason
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then((result) => {
                    if (result) {
                        this.loading = false;
                        this.dialog = false;
                        this.$emit('capture')
                    }
                })
            }
        }
    }
</script>
