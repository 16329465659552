<template>
    <v-dialog v-model="dialog" max-width="400">
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <template v-slot:activator="{ on }">
            <v-btn
                icon
                v-on="on"
                :disabled="disabled"
                color="error"
                x-small
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
        <v-card flat>
            <v-card-title>
                Really delete asset?
            </v-card-title>
            <v-card-text>
                You are about to delete this asset. This action cannot be undone. Proceed?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    rounded
                    color="error"
                    :loading="loading"
                    @click="archiveAsset"
                >
                    Delete asset
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

</template>

<script>
    import { archiveAsset } from '@/graphql'

    export default {
        name: 'ArchiveAsset',
        props: ['asset', 'disabled'],
        methods: {
            archiveAsset () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: archiveAsset,
                    variables: {
                        asset: this.asset.id,
                    }
                }).then((response) => {
                    if (response) {
                        this.$emit('archived');
                        this.loading = false;
                        this.dialog = false
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                })
            }
        },
        data: () => ({
            loading: false,
            dialog: false,
            error: false,
            errorMessage: null
        })
    }
</script>
