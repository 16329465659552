<template>
    <v-dialog max-width="400" v-model="dialog" v-if="accountData">
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <template v-slot:activator="{ on }">
            <v-btn
                icon
                v-on="on"
                x-small
                color="primary"
            >
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <v-card>
            <nice-header>Edit user {{ profile.user.firstName }}</nice-header>
            <v-card-text>
            <v-form v-model="valid">
                <v-row>
                    <v-col cols="12" class="py-0">
                        <v-text-field
                            v-model="form.name"
                            label="User name"
                            :rules="required"
                            outlined
                            rounded
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0" v-if="(subscription && subscription.plan.product.name === 'Advanced') || accountData.ltdCustomer">
                      <v-autocomplete
                              rounded
                              outlined
                              multiple
                              clearable
                              label="Stores"
                              item-text="name"
                              chips
                              v-model="form.stores"
                              item-value="hashId"
                              :items="stores"
                              :errorMessages="storeErrors"
                              :disabled="form.admin"
                              :persistent-hint="form.admin"
                              :hint="form.admin ? 'Admin users can access all stores' : 'Select which stores you want to give this user access to'"
                          >
                          </v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-switch
                            persistent-hint
                            :error-messages="errors"
                            :disabled="!user.profile.admin"
                            label="Admin user?"
                            v-model="form.admin"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12">
                        <v-switch
                            class="mt-0"
                            :disabled="!user.profile.canAddUsers || user.username === profile.user.username"
                            label="User management"
                            v-model="form.canAddUsers"
                            hint="Allows this user to create and manage users on the stores to which they have access."
                            persistent-hint
                        ></v-switch>
                    </v-col>
                    <v-col cols="12">
                    <v-switch
                        class="mt-0"
                        :disabled="!user.profile.canEditStore"
                        label="Store management"
                        v-model="form.canEditStore"
                        hint="Users can edit all details (e.g. name, address) of stores they have access to"
                        persistent-hint
                    ></v-switch>
                    </v-col>
                    <v-col cols="12">
                    <v-switch
                        class="mt-0"
                        :disabled="!user.profile.canCreateStores"
                        label="Store creation"
                        v-model="form.canCreateStores"
                        hint="Users can create more stores, as long as your current Rentify subscription permits it"
                        persistent-hint
                    ></v-switch>
                    </v-col>
                    <v-col cols="12">
                    <v-switch
                        class="mt-0"
                        :disabled="!user.profile.canEditBilling"
                        label="Billing management"
                        v-model="form.canEditBilling"
                        hint="Users can manage the Stripe integration for the stores they have access to"
                        persistent-hint
                    ></v-switch>
                    </v-col>
                </v-row>
            </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    rounded
                    outlined
                    :disabled="!valid"
                    :loading="loading"
                    @click="save"
                >Save changes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { accountData } from '@/graphql'
    import { subscription } from '@/graphql'
    import { stores, editUser } from "@/graphql";
    import { user } from '@/graphql'

    export default {
        name: 'EditUser',
        props: ['profile', 'users'],
        apollo: { stores, subscription, accountData, user },
        data: () => ({
            dialog: false,
            form: {
                name: null,
                admin: false,
                stores: [],
                canAddUsers: false,
                canEditStore: false,
                canEditBilling: false,
                canCreateStores: false
            },
            valid: null,
            required: [v => !!v || 'Please supply a name'],
            loading: false,
            error: false,
            errorMessage: null
        }),
        computed: {
            storeErrors () {
              let errors = []
              if (!this.form.admin && this.form.stores.length === 0) {
                errors.push('You must select at least one store, or make this user an admin')
              }
              return errors
            },
            errors () {
                let admins = this.users.filter(user => user.admin);
                if (this.user.profile.admin && admins.length === 0 && !this.form.admin) {
                    return ['You must have at least one admin user']
                }
                if (admins.length === 1 && admins[0].user.username === this.profile.user.username && !this.form.admin) {
                  return ['You can\'t downgrade your account because you are the only admin']
                }
                return []
            },
            disableAdmin () {
                const admins = this.users.filter(user => user.admin);
                return admins.length === 1
            }
        },
        methods: {
            save () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: editUser,
                    variables: {
                        user: this.profile.user.username,
                        ...this.form
                    }
                }).then((result) => {
                    if (result) {
                        this.loading = false;
                        this.dialog = false;
                        this.$emit('save')
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                })
            }
        },
        mounted() {
            if (this.profile) {
                this.form.name = this.profile.user.firstName;
                this.form.admin = this.profile.admin
                this.form.stores = this.profile.accessibleStores.map(store => store.hashId)
                this.form.canCreateStores = this.profile.canCreateStores
                this.form.canAddUsers = this.profile.canAddUsers
                this.form.canEditStore = this.profile.canEditStore
                this.form.canEditBilling = this.profile.canEditBilling
            }
        }
    }
</script>
