var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.bookings,"loading":_vm.$apollo.loading,"disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Bookings for "+_vm._s(_vm.product.name))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-5"},[_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-circle")]),_vm._v(" "+_vm._s(item.order.customer.name))],1),_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email")]),_vm._v(" "+_vm._s(item.order.customer.email))],1),_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-phone")]),_vm._v(" "+_vm._s(item.order.customer.phoneNumber))],1)])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.order.status)+" ")]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("niceDate")(item.startDatetime))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("niceDate")(item.endDatetime))+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assets.length)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/orders/" + (item.order.id))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('a',{staticClass:"primary--text",staticStyle:{"text-decoration":"none"},attrs:{"href":href,"target":"_blank"}},[_vm._v(" View order "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-launch")])],1)]}}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }