<template>
    <v-dialog max-width="600" v-model="dialog">
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <v-card flat>
            <nice-header>Create a new customer</nice-header>
            <v-card-text>
                <v-form v-model="valid">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                    label="Customer name"
                                    v-model="form.name"
                                    :rules="[rules.required]"
                                    rounded
                                    outlined
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                    label="Customer email"
                                    v-model="form.email"
                                    :rules="[rules.required, rules.email]"
                                    rounded
                                    outlined
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                    label="Phone number"
                                    v-model="form.phone"
                                    :rules="[rules.required, rules.numeric]"
                                    rounded
                                    outlined
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        rounded
                        outlined
                        color="primary"
                        :loading="loading"
                        :disabled="!valid"
                        @click="createCustomer"
                >Create customer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {createCustomer} from '@/graphql'
    import { mapState } from 'vuex'

    export default {
        name: 'NewCustomerDialog',
        props: ['name', 'value'],
        data: () => ({
            valid: null,
            form: {
                name: null,
                email: null,
                phone: null
            },
            rules: {
                required: v => !!v || 'This field is required',
                numeric: v => !isNaN(v) || 'Must be a valid number',
                email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            },
            loading: false,
            error: false,
            errorMessage: null
        }),
        watch: {
            dialog () {
                this.form.name = this.name
            }
        },
        computed: {
            ...mapState(['selectedStore']),
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            }
        },
        methods: {
            createCustomer() {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: createCustomer,
                    variables: {
                        store: this.selectedStore,
                        ...this.form
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then(({ data }) => {
                    const { pk } = data.createCustomer;
                    this.dialog = false;
                    this.$emit('create', pk);
                    this.loading = false
                })
            }
        }
    }
</script>
