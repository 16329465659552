<template>
    <v-toolbar flat color="white">
        <v-toolbar-title
            :class="small ? 'subtitle-1' : 'h3'"
        >
            <slot></slot>
        </v-toolbar-title>
        <v-divider
                v-if="!small"
                class="mx-4"
                inset
                vertical
        ></v-divider>
        <slot name="extra"></slot>
    </v-toolbar>
</template>

<script>
    export default {
        name: 'NiceHeader',
        props: ['title'],
        computed: {
            small () {
                return 'small' in this.$attrs
            }
        }

    }
</script>
