import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueApollo from "vue-apollo";
import apollo from "./plugins/apollo";
import autocomplete from "./plugins/autocomplete"
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

import vuetify from './plugins/vuetify';
import GlobalComponents from './plugins/globalComponents'
import store from './store'

// HTTP connection to the API
const httpLink = createHttpLink({
  uri:  process.env.NODE_ENV === 'development' ? 'http://localhost:8000/graphql' : 'https://api.rentify.store/graphql',
  credentials: 'include',
  opts:{
    credentials:'include'
  },
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  fetchOptions:{
    credentials:'include'
  },
  cache,
  credentials: 'include',
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});


Vue.config.productionTip = false;

Vue.use(GlobalComponents);

new Vue({
  router,
  apollo,
  apolloProvider,
  vuetify,
  autocomplete,
  store,
  render: h => h(App)
}).$mount('#app');
