<template>
    <v-combobox
        v-if="allowAdd"
        rounded
        outlined
        :item-text="itemText"
        :item-value="itemValue"
        v-bind="{ ...$props, ...$attrs }"
        v-model="model"
    ></v-combobox>
    <v-autocomplete
        v-else
        rounded
        :item-text="itemText"
        :item-value="itemValue"
        outlined
        v-bind="{ ...$props, ...$attrs }"
        v-model="model"
    >
    </v-autocomplete>
</template>

<script>
    export default {
        props: ['label',  'value', 'rules', 'allowAdd', 'items', 'itemText', 'itemValue'],
        name: 'Autocomplete',
        computed: {
            model: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>
