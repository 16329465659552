var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"elevation-0 grey lighten-2",staticStyle:{"border-bottom":"solid 1px rgb(200, 200, 200) !important"},attrs:{"app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleDrawer($event)}}},[(_vm.drawer)?_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-arrow-left-circle-outline")]):_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-arrow-right-circle-outline")])],1),_c('v-toolbar-title',{domProps:{"textContent":_vm._s(_vm.routeName)}}),_c('v-spacer'),(_vm.stores && _vm.stores.length > 0)?_c('v-autocomplete',{staticClass:"mt-6",attrs:{"rounded":"","label":"Selected store","outlined":"","item-text":"name","item-value":"hashId","items":_vm.stores,"dense":""},scopedSlots:_vm._u([(_vm.store)?{key:"append",fn:function(){return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.storeDomain,"target":"_blank"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-store")])]}}],null,false,1703462940)},[_vm._v(" View store ")])],1)]},proxy:true}:null],null,true),model:{value:(_vm.store),callback:function ($$v) {_vm.store=$$v},expression:"store"}}):_vm._e(),_c('v-spacer'),(_vm.notifications)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"content":_vm.notifications.length,"overlap":"","value":_vm.notifications.length > 0,"color":"accent"}},[_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}],null,false,4054060274)},[(_vm.notifications.length > 0)?_c('v-list',[_vm._l((_vm.notifications.slice(0,5)),function(note,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(note.message))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("niceTime")(note.created)))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.clearNote(note.id)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)}),(_vm.notifications.length > 5)?_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("+ "+_vm._s(_vm.notifications.length - 5)+" more notifications")])],1):_vm._e()],2):_vm._e()],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","link":"","to":"/account"}},[_c('v-icon',[_vm._v("mdi-cog-outline")])],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" mdi-logout ")])],1),_c('v-list-item-title',{staticClass:"mr-5"},[_vm._v("Log out")])],1)],1)],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.helpBar = !_vm.helpBar}}},[_c('v-icon',[_vm._v("mdi-help")])],1)],1),_c('help-drawer',{model:{value:(_vm.helpBar),callback:function ($$v) {_vm.helpBar=$$v},expression:"helpBar"}},[(_vm.$route.name === 'Dashboard')?_c('div',[_c('dashboard-help')],1):(_vm.$route.name === 'Inventory')?_c('div',[_c('inventory-help')],1):(_vm.$route.name === 'Product Details')?_c('div',[_c('product-details-help')],1):(_vm.$route.name === 'Orders')?_c('div',[_c('orders-help')],1):(_vm.$route.name === 'Order view')?_c('div',[_c('order-view-help')],1):(_vm.$route.name === 'My account')?_c('div',[_c('account-view-help')],1):(_vm.$route.name === 'My Store')?_c('div',[_c('store-help')],1):_c('div',[_c('v-card-text',[_vm._v(" No help is available for this page ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }