<template>
    <v-dialog v-model="dialog" max-width="600">
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <template v-slot:activator="{ on }">
            <v-btn
                x-small
                v-on="on"
                icon
                color="primary"
            >
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <v-card flat>
            <nice-header>Edit this asset</nice-header>
            <v-container>
                <v-form v-model="valid">
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <text-field
                                    label="SKU"
                                    v-model="form.sku"
                                    :rules="[required]"
                            >
                            </text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                            <autocomplete
                                    :label="store.labelOne"
                                    v-model="form.size"
                                    :allow-add="true"
                                    :items="store.labelOneChoices"
                                    item-text="value"
                                    item-value="value"
                            >
                            </autocomplete>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                            <autocomplete
                                    :label="store.labelTwo"
                                    item-value="value"
                                    v-model="form.gender"
                                    :items="store.labelTwoChoices"
                                    :allow-add="true"
                            >
                            </autocomplete>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                            <autocomplete
                                    :label="store.labelThree"
                                    v-model="form.brand"
                                    :items="store.labelThreeChoices"
                                    :allow-add="true"
                            >
                            </autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined rounded color="primary" :loading="loading" @click="saveChanges">Save changes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { accountData, editAsset, stores } from '@/graphql'
    import { mapState } from 'vuex'

    export default {
        name: 'EditAsset',
        props: ['asset'],
        apollo: { accountData, stores },
        computed: {
            ...mapState(['selectedStore']),
            store () {
                if (this.selectedStore) {
                    return this.$stores.find(store => store.hashId === this.selectedStore)
                }
                return null
            }
        },
        data: () => ({
            dialog: false,
            valid: null,
            form: {
                brand: null,
                size: null,
                gender: null,
                sku: null
            },
            required: v => !!v || 'This field is required',
            loading: false,
            error: false,
            errorMessage: null
        }),
        mounted () {
            this.updateForm()
        },
        watch: {
            asset () {
                this.updateForm()
            }
        },
        methods: {
            updateForm () {
                if (this.asset) {
                    this.form.sku = this.asset.sku;
                    this.form.gender = this.asset.gender;
                    this.form.brand = this.asset.brand;
                    this.form.size = this.asset.size;
                }
            },
            saveChanges () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: editAsset,
                    variables: {
                        asset: this.asset.id,
                        ...this.form
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then((result) => {
                    if (result) {
                        this.$emit('save');
                        this.dialog = false;
                        this.loading = false
                    }
                })
            }
        }
    }
</script>
