<template>
    <v-main class="primary">
            <v-main>
                <v-container fluid fill-height>
                    <v-row
                            align="center"
                            justify="center"
                    >
                        <v-col
                                cols="12"
                                sm="8"
                                md="5"
                        >
                            <v-card outlined class="rounded-xl pa-10">
                                <slot></slot>
                             </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-main>

        <v-footer
                app
                class="elevation-0 transparent"
        ></v-footer>
    </v-main>
</template>

<script>
    export default {
        name: 'AuthWrapper'
    }
</script>
