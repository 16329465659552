<template>
    <modal
        v-model="dialog"
        max-width="600"
        btn-text="Mark as returned"
        title="Mark order as returned"
    >
        <v-card-text>
            <v-alert v-if="order.deposit" type="warning" outlined>
                This order is subject to a security deposit. Once you have marked this order as having been returned,
                you will no longer have the option to retain the deposit
            </v-alert>

            You are about to mark this order as returned. This action cannot be undone. Proceed?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="success"
                rounded
                outlined
                :loading="loading"
                :disabled="disabled"
                @click="updateOrder"
            >
                Mark as returned
            </v-btn>
        </v-card-actions>
    </modal>
</template>

<script>
    import { updateOrder } from '@/graphql'
    export default {
        name: 'CompleteHireButton',
        props: ['order', 'newStatus', 'disabled', 'btnText'],
        data: () => ({
            loading: false,
            error: false,
            errorMessage: null
        }),
        methods: {
            updateOrder() {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: updateOrder,
                    variables: {
                        pk: this.order.id,
                        newStatus: this.newStatus
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message;
                    this.loading = false
                }).then(() => {
                    this.$emit('changed');
                    this.loading = false
                })
            }
        }

    }
</script>
