<template>
    <div>
        <v-card-text>
            This page lets you edit your store's <strong>name, address, currency</strong> and <strong>opening hours</strong>.
        </v-card-text>

        <v-subheader class="title">About currencies</v-subheader>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
            At present, a single Rentify store can only accept payments in a single currency. You should set
            your currency before attempting to add any products or pricing plans to your inventory - if you want to
            change your currency later, you'll first need to delete any pricing plans in the existing currency,
            change the currency, then add new pricing plans for the new currency
        </v-card-text>

        <v-subheader class="title">Store name and address</v-subheader>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
            The store name and address will be visible to your customers in your Rentify store. The address
            will appear in your customers' order details page as the location from which they can collect their orders.
        </v-card-text>

        <v-subheader class="title">Opening times</v-subheader>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
            Use this section to specify the times of first collection/last dropoff each day of the week. For example,
            if you set an opening time to 9AM, if your customer makes an order for a daily hire, this is the time that
            the customer will be told they can pick up the item.
        </v-card-text>

        <v-card-text>
            Setting the opening hours correctly is very important, as this will impact the availability of items in
            your store. If you enter invalid opening hours for your store, your customers may be able to make
            bookings for times where you are closed.
        </v-card-text>

        <v-subheader class="title">One-off closures</v-subheader>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
            In addition to regular opening hours, Rentify also lets you add non-regular, one-off store closures
            to cover things like national holidays. When you mark your store as being closed on a specific date, your
            customers will not be able to make bookings that start or end on this day
        </v-card-text>

        <v-card-text>
            However, if you have a plan that supports this, they will still be able to place orders to collect items
            before a store closure and return it after
        </v-card-text>

    </div>
</template>

<script>
    export default {
        name: 'StoreHelp'
    }
</script>
