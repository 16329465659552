<template>
    <span>
        <v-snackbar color="error" v-model="error">{{ errorMessage }}</v-snackbar>
        <v-btn
                :loading="loading"
                @click="updateOrder"
                outlined
                rounded
                :disabled="disabled"
                color="success"
                class="mx-2"
        >
            <slot></slot>
        </v-btn>
    </span>
</template>

<script>
    import { updateOrder } from '@/graphql'
    export default {
        name: 'UpdateOrderButton',
        props: ['order', 'newStatus', 'disabled'],
        data: () => ({
            loading: false,
            error: false,
            errorMessage: null
        }),
        methods: {
            updateOrder() {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: updateOrder,
                    variables: {
                        pk: this.order.id,
                        newStatus: this.newStatus
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message;
                    this.loading = false
                }).then(() => {
                    this.$emit('changed');
                    this.loading = false
                })
            }
        }

    }
</script>
