import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);


const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Dashboard',
            icon: 'mdi-apps',
            component: () => import('../views/Home.vue'),
        },
        {
            path: '/inventory',
            name: 'Inventory',
            icon: 'mdi-clipboard-list-outline',
            component: () => import('../views/Inventory.vue'),
        },
        {
            path: '/enquiries',
            name: 'Enquiries',
            icon: 'mdi-message-text',
            component: () => import('../views/Enquiries.vue'),
        },
        {
            path: '/appsumo',
            name: 'AppSumo',
            component: () => import('../views/AppSumo.vue'),
        },
        {
            path: '/linksumo',
            name: 'LinkSumo',
            component: () => import('../views/LinkSumo.vue'),
        },
        {
            path: '/appsumo/:token',
            name: 'AppSumo Token',
            component: () => import('../views/AppSumo.vue'),
        },
        {
            path: '/linksumo/:token',
            name: 'LinkSumo Token',
            component: () => import('../views/LinkSumo.vue'),
        },
        {
            path: '/products/:product/create-order/',
            name: 'Create order',
            icon: 'mdi-message-text',
            component: () => import('../views/CreateOrder.vue'),
        },
        {
            path: '/orders',
            name: 'Orders',
            icon: 'mdi-clipboard-text-multiple',
            component: () => import('../views/Orders.vue'),
        },
        {
            path: '/schedule',
            name: 'Schedule',
            icon: 'mdi-calendar',
            component: () => import('../views/Schedule.vue'),
        },
        {
            path: '/store',
            name: 'My Store',
            icon: 'mdi-store',
            component: () => import('../views/Store.vue'),
        },
        {
            path: '/billing',
            name: 'My Billing',
            icon: 'mdi-credit-card',
            component: () => import('../views/Billing.vue'),
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('../views/Login.vue'),
        }, {
            path: '/register',
            name: 'Register',
            component: () => import('../views/Register.vue'),
        }, {
            path: '/product/:id',
            name: 'Product Details',
            component: () => import('../views/Product.vue'),
        }, {
            path: '/orders/:id',
            name: 'Order view',
            component: () => import('../views/Order.vue'),
        }, {
            path: '/account',
            name: 'My Account',
            component: () => import('../views/Account.vue'),
        }, {
            path: '/users',
            name: 'My Team',
            component: () => import('../views/Team.vue'),
        }, {
            path: '/set-password/:secret',
            name: 'Set Password',
            component: () => import('../views/SetPassword.vue'),
        }, {
            path: '/newstore',
            name: 'Create new store',
            component: () => import('../views/CreateStore.vue'),
        }, {
            path: '/coupons',
            name: 'Coupons',
            component: () => import('../views/Coupons.vue'),
        }, {
            path: '/theme',
            name: 'Store appearance',
            component: () => import('../views/Theme.vue'),
        },
    ]
});

export default router
