<template>
    <div>
    <v-app-bar
            app
            class="elevation-0 grey lighten-2"
            style="border-bottom: solid 1px rgb(200, 200, 200) !important"
    >
        <v-app-bar-nav-icon @click.stop="toggleDrawer">
            <v-icon v-if="drawer" large>mdi-arrow-left-circle-outline</v-icon>
            <v-icon v-else large>mdi-arrow-right-circle-outline</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title v-text="routeName"></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-autocomplete
          v-if="stores && stores.length > 0"
          rounded
          label="Selected store"
          class="mt-6"
          outlined
          v-model="store"
          item-text="name"
          item-value="hashId"
          :items="stores"
          dense
        >
           <template v-slot:append v-if="store">
             <a :href="storeDomain" target="_blank" style="text-decoration: none">
               <v-tooltip top>
                 <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-store</v-icon>
                 </template>
                 View store
               </v-tooltip>

             </a>
           </template>
        </v-autocomplete>

        <v-spacer></v-spacer>

        <v-menu bottom left v-if="notifications">
            <template v-slot:activator="{ on }">
                <v-badge :content="notifications.length" overlap :value="notifications.length > 0" color="accent">
                    <v-btn icon v-on="on">
                        <v-icon>mdi-bell</v-icon>
                    </v-btn>
                </v-badge>
            </template>
            <v-list v-if="notifications.length > 0">
                <v-list-item v-for="(note, i) in notifications.slice(0,5)" :key="i" >
                    <v-list-item-content>
                        <v-list-item-title>{{ note.message }}</v-list-item-title>
                        <v-list-item-subtitle>{{ note.created | niceTime }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn icon @click="clearNote(note.id)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="notifications.length > 5">
                    <v-list-item-subtitle>+ {{notifications.length - 5 }} more notifications</v-list-item-subtitle>
                </v-list-item>


            </v-list>
        </v-menu>


        <v-btn icon link to="/account">
            <v-icon>mdi-cog-outline</v-icon>
        </v-btn>

        <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                >
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item @click="logout" link>
                    <v-list-item-action>
                        <v-icon>
                            mdi-logout
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-title class="mr-5">Log out</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-btn icon @click="helpBar = !helpBar">
            <v-icon>mdi-help</v-icon>
        </v-btn>
    </v-app-bar>
        <help-drawer
            v-model="helpBar"
        >
            <div v-if="$route.name === 'Dashboard'">
                <dashboard-help/>
            </div>
            <div v-else-if="$route.name === 'Inventory'">
                <inventory-help/>
            </div>
            <div v-else-if="$route.name === 'Product Details'">
                <product-details-help/>
            </div>
            <div v-else-if="$route.name === 'Orders'">
                <orders-help/>
            </div>
            <div v-else-if="$route.name === 'Order view'">
                <order-view-help/>
            </div>
            <div v-else-if="$route.name === 'My account'">
                <account-view-help/>
            </div>
            <div v-else-if="$route.name === 'My Store'">
                <store-help/>
            </div>
            <div v-else>
                <v-card-text>
                    No help is available for this page
                </v-card-text>
            </div>
        </help-drawer>
    </div>
</template>

<script>
    import { BACKEND_DOMAIN, BACKEND_ROOT } from "../variables";
    import Cookie from 'js-cookie';
    import moment from 'moment';
    import { notifications, stores, clearNotification, accountData } from "@/graphql";

    export default {
        name: 'AppBar',
        props: ['drawer', 'routeName'],
        apollo: { accountData, notifications, stores },
        computed: {
            store: {
              get () {
                return this.$store.state.selectedStore
              },
              set (store) {
                this.$store.commit('setStore', { store })
              }
            },
            storeDomain () {
                if (process.env.NODE_ENV === 'development') {
                  return `http://${this.store}.localhost.test:8081`
                } else {
                  return `https://${this.store}.rentify.store`
                }

            }
        },
        watch: {
          stores (value) {
            if (!this.store) {
              this.store = value[0].hashId
            }
          }
        },
        filters: {
            niceTime (val) {
                return moment(val).calendar()
            }
        },
        data: () => ({
            helpBar: false,
        }),
        methods: {
            toggleDrawer() {
                this.$emit('toggle-drawer')
            },
            clearNote (notification) {
                this.$apollo.mutate({
                    mutation: clearNotification,
                    variables: { notification }
                }).then(() => {
                    this.$apollo.queries.notifications.refetch()
                })
            },
            logout () {
                if (BACKEND_DOMAIN) {
                    Cookie.remove('JWT', { domain: BACKEND_DOMAIN });
                    Cookie.remove('JWT');
                    Cookie.remove('csrftoken', { domain: BACKEND_DOMAIN });
                    Cookie.remove('csrftoken');
                } else {
                    Cookie.remove('JWT');
                    Cookie.remove('csrftoken');
                }
                window.location = `${BACKEND_ROOT}/authentication/logout`
            }

        }

    }
</script>
