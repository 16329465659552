<template>
    <v-dialog v-model="dialog" max-width="500" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-snackbar v-model="error" color="error">{{errorMessage}}</v-snackbar>
        <template v-slot:activator="{ on }">
            <v-btn
                outlined
                rounded
                color="primary"
                v-on="on"
            >
                Add one-off store closure
            </v-btn>
        </template>
        <v-card flat>
            <v-card-title>
                Add one-off store closure
                <v-spacer></v-spacer>
                <v-btn icon v-if="$vuetify.breakpoint.xsOnly" @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-form v-model="valid">
                <v-card-text>
                    <v-date-picker :landscape="$vuetify.breakpoint.mdAndUp" :width="$vuetify.breakpoint.xsOnly ? '100%' : null" v-model="date" :rules="required"></v-date-picker>
                </v-card-text>
            </v-form>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    rounded
                    outlined
                    color="primary"
                    :loading="loading"
                    @click="addClosure"
                    :disabled="!valid"
                >
                    Add closure
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {addClosure} from '@/graphql'
    import {mapState} from "vuex";
    export default {
        name: 'OneOffClosure',
        data: () => ({
            error: false,
            errorMessage: null,
            dialog: false,
            valid: null,
            date: null,
            loading: false,
            required: v => !!v || 'Please select a date'
        }),
        computed: {
          ...mapState(['selectedStore']),
        },
        methods: {
            addClosure () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: addClosure,
                    variables: {
                        closedDate: this.date,
                        catalog: this.selectedStore,
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then((result) => {
                    if (result) {
                        this.loading = false;
                        this.$emit('created');
                        this.dialog = false
                    }
                })

            }
        }
    }
</script>
