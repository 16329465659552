<template>
    <v-dialog v-model="dialog" max-width="600">
        <v-card flat v-if="dialog">
            <nice-header>Message from {{ enquiry.customer.name }}</nice-header>
            <v-card-text>
                <div>
                    <div><span><v-icon small>mdi-email</v-icon></span> {{ enquiry.customer.email }}</div>
                    <div v-if="enquiry.product"><span><v-icon small>mdi-clipboard</v-icon></span> {{ enquiry.product.name}}</div>
                </div>
            </v-card-text>
            <v-card-text v-html="enquiry.message.replace('\n', '<br/><br/>')">
            </v-card-text>
            <v-card-actions v-if="enquiry.product">
                <v-spacer></v-spacer>
                <v-btn
                    link
                    :to="`/products/${enquiry.product.id}/create-order/?customer=${enquiry.customer.id}`"
                    rounded
                    outlined
                    color="primary"
                >
                    Create order
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'EnquiryDialog',
        props: ['enquiry'],
        computed: {
            dialog: {
                get () {
                    return !!this.enquiry
                },
                set () {
                    this.$emit('clearDialog')
                }
            }
        }

    }
</script>
