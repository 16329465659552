<template>
  <v-dialog v-model="dialog" max-width="400" v-if="accountData">
    <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
    <template v-slot:activator="{ on }">
      <v-btn
        outlined
        rounded
        color="primary"
        v-on="on"
        :disabled="accountData && !accountData.canCreateSubusers"
      >Create new user</v-btn>
    </template>
    <v-card flat>
      <nice-header>Add new user</nice-header>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                outlined
                rounded
                label="Name"
                :rules="[rules.required]"
                v-model="form.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                outlined
                rounded
                label="Email"
                :rules="[rules.required, rules.email]"
                v-model="form.email"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
              v-if="(subscription && subscription.plan.product.name === 'Advanced') || accountData.ltdCustomer"
            >
              <v-autocomplete
                rounded
                outlined
                multiple
                clearable
                label="Stores"
                item-text="name"
                chips
                v-model="form.stores"
                item-value="hashId"
                :items="stores"
                :errorMessages="storeErrors"
                :disabled="form.admin"
                :persistent-hint="form.admin"
                :hint="form.admin ? 'Admin users can access all stores' : 'Select which stores you want to give this user access to'"
              ></v-autocomplete>
            </v-col>
            <v-subheader>Roles</v-subheader>
            <v-col cols="12">
              <v-switch
                class="mt-0 pl-3"
                label="Admin user"
                :disabled="!user.profile.admin"
                v-model="form.admin"
                :hint="user.profile.admin ? 'Admin have access to all stores and features, including managing your Rentify subscription.' : 'Only admins can create more admin users'"
                persistent-hint
              ></v-switch>
            </v-col>
            <v-col cols="12">
              <v-switch
                class="mt-0 pl-3"
                :disabled="!user.profile.canAddUsers"
                label="User management"
                v-model="form.canAddUsers"
                hint="Allows this user to create and manage users on the stores to which they have access."
                persistent-hint
              ></v-switch>
            </v-col>
            <v-col cols="12">
              <v-switch
                class="mt-0 pl-3"
                :disabled="!user.profile.canEditStore"
                label="Store management"
                v-model="form.canEditStore"
                hint="Users can edit all details (e.g. name, address) of stores they have access to"
                persistent-hint
              ></v-switch>
            </v-col>
            <v-col cols="12">
              <v-switch
                class="mt-0 pl-3"
                :disabled="!user.profile.canCreateStores"
                label="Store creation"
                v-model="form.canCreateStores"
                hint="Users can create more stores, as long as your current Rentify subscription permits it"
                persistent-hint
              ></v-switch>
            </v-col>
            <v-col cols="12">
              <v-switch
                class="mt-0 pl-3"
                :disabled="!user.profile.canEditBilling"
                label="Billing management"
                v-model="form.canEditBilling"
                hint="Users can manage the Stripe integration for the stores they have access to"
                persistent-hint
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          outlined
          color="primary"
          :loading="loading"
          :disabled="!valid"
          @click="create"
        >Create user</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {accountData, createSubuser, subscription} from "@/graphql";
import { stores } from "@/graphql";
import { user } from '@/graphql'

export default {
  name: "NewUserForm",
  apollo: { accountData, stores, subscription, user },
  computed: {
    storeErrors() {
      let errors = [];
      if (!this.form.admin && this.form.stores.length === 0) {
        errors.push(
          "You must select at least one store, or make this user an admin"
        );
      }
      return errors;
    }
  },
  data: () => ({
    dialog: false,
    valid: null,
    form: {
      name: null,
      email: null,
      admin: false,
      stores: [],
      canAddUsers: false,
      canEditStore: false,
      canCreateStores: false,
      canEditBilling: false
    },
    rules: {
      required: v => !!v || "This field is required",
      email: v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    },
    loading: false,
    error: false,
    errorMessage: null
  }),
  methods: {
    create() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: createSubuser,
          variables: this.form
        })
        .catch(error => {
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
          this.loading = false;
        })
        .then(result => {
          if (result) {
            this.dialog = false;
            this.$emit("create");
            this.$refs.form.reset();
            this.loading = false;
          }
        });
    }
  }
};
</script>
