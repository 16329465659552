<template>
    <v-form v-model="valid" ref="form">
        <v-row>
            <v-col cols="12" md="4" offset-md="1">
                <v-file-input
                    :loading="loading"
                    show-size
                    counter
                    rounded
                    outlined
                    v-model="file"
                    accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                    :rules="rules"
                    label="Select file to upload"
                ></v-file-input>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="description"
                    :rules="required"
                    label="Document type"
                    outlined
                    rounded
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn
                    outlined
                    x-large
                    rounded
                    color="primary"
                    block
                    :disabled="!valid"
                    @click="uploadFiles"
                    :loading="loading"
                >Upload</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import { user, createDocument, uploadDocument } from '@/graphql'

    export default {
        name: 'UploadLegalNotice',
        props: ['catalog'],
        data: () => ({
            file: null,
            valid: null,
            rules: [
                v => !!v || 'Please upload a file'
            ],
            loading: false,
            error: false,
            errorMessage: null,
            uploadedFiles: {},
            description: null,
            required: [
                v => !!v || 'Please enter a description'
            ]
        }),
        apollo: { user },
        computed: {
            allUploaded () {
                var result = true;
                for (var i in this.uploadedFiles) {
                    var file = this.uploadedFiles[i];
                    if (!file) result = false
                }
                return result
            }
        },
        methods: {
            linkDocument (key) {
                this.$apollo.mutate({
                    mutation: createDocument,
                    variables: {
                        description: this.description,
                        catalog: this.catalog,
                        key
                    }
                }).then(() => {
                    this.$refs.form.reset();
                    var self = this;
                    setTimeout(() => {
                        self.$emit('uploaded');
                    }, 500);
                    this.loading = false;
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message;
                    this.loading = false
                })
            },
            async uploadFiles() {
                this.loading = true;
                const {data} = await this.$apollo.mutate({
                    mutation: uploadDocument,
                    variables: {
                        catalog: this.catalog,
                        filename: this.file.name
                    }
                });
                const { key, credentials, policy, signature, url, algorithm, date } = data.documentPresignedUploadUrl;
                var form = new FormData();

                form.append('Content-Type', this.file.type);
                form.append('key', key);
                form.append('policy', policy);
                form.append('X-Amz-Credential', credentials);
                form.append('X-Amz-Signature', signature);
                form.append('X-Amz-Algorithm', algorithm);
                form.append('X-Amz-Date', date);
                form.append("file", this.file);

                var xhr = new XMLHttpRequest();
                // xhr.upload.addEventListener("progress", uploadProgress, false);
                xhr.addEventListener("load", this.linkDocument(key), false);
                // xhr.addEventListener("error", uploadFailed, false);
                // xhr.addEventListener("abort", uploadCanceled, false);
                xhr.open('POST', url);
                xhr.send(form);
            }
        }
    }
</script>
