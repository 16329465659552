<template>
    <div>
        <v-card-text>
            The Orders view shows you all orders made on your Rentify store.
        </v-card-text>
        <v-card-text>
            You can use the three filters at the top of the page to help you find specific orders you are interested in.
            The <strong>start date</strong> filter lets you find orders on a specific date. The <strong>status</strong>
            filter shows you orders in one or more statuses. Finally, the <strong>search</strong> filter lets you find
            an order by searching for a customer name, email address or order number.
        </v-card-text>
        <v-card-text>
            The controls at the bottom of the page let you scroll through multiple pages of orders, where applicable.
        </v-card-text>
        <v-card-text>
            To view details of a specific order, simply click on the green pencil icon,
            <v-icon small color="primary">mdi-pencil</v-icon>, to view the order details page of a specific order.
        </v-card-text>
    </div>
</template>

<script>
    export default {
        name: 'OrdersHelp'
    }
</script>
