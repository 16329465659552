<template>
    <div>
        <v-card-text>
            The order view page shows you the full details of the current order, and lets you <strong>confirm,
            cancel, start</strong> or <strong>complete</strong> orders.
        </v-card-text>

        <v-subheader class="title">Confirming orders</v-subheader>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
            If you haven't yet enabled card payments in your store, any order created by a customer will be created
            with a status of <strong>Pending</strong>, and the payment method will be marked as <strong>Payment
            on collection</strong>. You should confirm all pending orders before the customer
            collects their items for hire, by clicking on the <strong>Confirm order</strong> button. This will send
            an email notification to the customer to let them know their order is confirmed.
        </v-card-text>
        <v-card-text>
            If you have enabled card payments, your customers will have to pay for their orders when checking out,
            and the order will be created with a status of <strong>Confirmed</strong>. You won't need to manually
            confirm these orders
        </v-card-text>

        <v-subheader class="title">Cancelling orders</v-subheader>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
            You can cancel any orders in the <strong>PENDING</strong> or <strong>CONFIRMED</strong> status by clicking
            on the red <strong>Cancel Order</strong> button. A notification will be sent to the customer to let them
            know, but you should contact them separately to let them know why you have cancelled it.
        </v-card-text>

        <v-subheader class="title">Starting and ending hires</v-subheader>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
            When your customer collects the items in their order, you should click the <strong>Start hire</strong>
            button to check out the associated assets. Once returned, click the <strong>Complete hire</strong>
            button to mark the items as returned.
        </v-card-text>

        <v-subheader class="title">Refunds</v-subheader>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
            If you have enabled card payments, its possible to issue refunds to customers for any orders marked as
            <strong>Cancelled</strong> using the <strong>Issue refund</strong> button. Your customer will receive
            a refund for the full order value. If you are still using a free account, the Rentify commission
            fee will be refunded. Credit card fees charge by
            <a target="_blank" style="text-decoration: none" href="https://stripe.com/">Stripe</a> are not refunded.
        </v-card-text>




    </div>
</template>

<script>
    export default {
        name: 'OrderViewHelp'
    }
</script>
