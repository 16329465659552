<template>
    <v-container>
        <v-container>
            <v-data-table
                    :headers="headers"
                    :items="bookings"
                    :loading="$apollo.loading"
                    disable-sort
            >
                <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-toolbar-title>Bookings for {{product.name}}</v-toolbar-title>
                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        ></v-divider>
                    </v-toolbar>
                </template>
                <template v-slot:item.customer="{ item }">
                    <div class="py-5">
                        <div><v-icon small>mdi-account-circle</v-icon> {{item.order.customer.name}}</div>
                        <div><v-icon small>mdi-email</v-icon> {{item.order.customer.email}}</div>
                        <div><v-icon small>mdi-phone</v-icon> {{item.order.customer.phoneNumber}}</div>
                    </div>
                </template>
                <template v-slot:item.status="{ item }">
                    {{ item.order.status }}
                </template>
                <template v-slot:item.start="{ item }">
                    {{ item.startDatetime | niceDate}}
                </template>
                <template v-slot:item.end="{ item }">
                    {{ item.endDatetime | niceDate }}
                </template>
                <template v-slot:item.quantity="{ item }">
                    {{ item.assets.length }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <router-link :to="`/orders/${item.order.id}`" v-slot="{ href }">
                        <a :href="href" class="primary--text" style="text-decoration: none" target="_blank"> View order <v-icon color="primary">mdi-launch</v-icon></a>
                    </router-link>
                </template>
            </v-data-table>
        </v-container>
    </v-container>
</template>

<script>
    import moment from 'moment'
    import { bookings } from '@/graphql'
    export default {
        name: 'ProductBookingTable',
        props: ['product'],
        apollo: {
            bookings: {
                query: bookings,
                variables () {
                    return {
                        product: this.product.id,
                    }
                }
            }
        },
        filters: {
            niceDate (value) {
                return moment.parseZone(value).calendar()
            }
        },
        data: () => ({
            headers: [
                {text: 'Customer', value: 'customer'},
                {text: 'Order status', value: 'status'},
                {text: 'Start date', value: 'start'},
                {text: 'Return date', value: 'end'},
                {text: 'Quantity', value: 'quantity'},
                {text: 'Actions', value: 'actions'},
            ]
        })
    }
</script>
