<template>
  <v-text-field
      rounded
      outlined
      autocomplete="new-password"
      :label="label"
      :name="name"
      :prepend-inner-icon="prependIcon"
      :append-icon="appendIcon"
      :type="type"
      :rules="rules"
      :prefix="prefix"
      :readonly="readOnly"
      :disabled="disabled"
      @click:append="handleClickAppend"
      v-model="model"
  ></v-text-field>
</template>

<script>
export default {
  name: 'TextField',
  props: [
    'value', 'label', 'name', 'prependIcon', 'appendIcon', 'type', 'rules', 'prefix', 'readOnly', 'disabled',
  ],
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    handleClickAppend() {
      this.$emit('click:append')
    }
  }
}
</script>
