<template>
    <v-list-item link :to="route.path">
        <v-list-item-action>
            <v-icon v-text="route.icon"></v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title v-text="route.name"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="route.showNewOrders && accountData && accountData.newOrders.length > 0">
            <v-chip class="accent" light rounded small>{{ accountData.newOrders }} new</v-chip>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
    import { accountData } from '@/graphql'

    export default {
        name: 'RouteItem',
        apollo: { accountData },
        props: ['route']
    }
</script>
