<template>
    <v-dialog max-width="500" v-model="dialog" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card flat>
            <v-card-title>
                Setup online payments for your account
                <v-spacer></v-spacer>
                <v-btn icon v-if="$vuetify.breakpoint.xsOnly" @click="$router.push('/account')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                In order to accept card payments online in your Rentify store, you must first configure your
                billing account. Rentify uses <a href="https://stripe.com">Stripe</a> to handle secure card
                payments online (credit card fees apply). To complete this process, follow the on screen steps below,
                starting off by selecting your region and clicking the <strong>Configure Stripe Account</strong>
                button
            </v-card-text>
            <v-form
                v-model="valid"
            >
                <v-card-text class="pb-0">
                    <v-autocomplete
                        outlined
                        rounded
                        v-model="country"
                        label="Select your country"
                        :items="countryChoices"
                        item-value="code"
                        item-text="name"
                    ></v-autocomplete>
                </v-card-text>
                <v-card-text class="pt-0">
                    <v-autocomplete
                        outlined
                        rounded
                        multiple
                        v-model="selectedStores"
                        label="Choose stores to link"
                        :items="unconnectedStores"
                        item-value="code"
                        item-text="name"
                    ></v-autocomplete>
                </v-card-text>
            </v-form>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    rounded
                    outlined
                    color="primary"
                    :loading="loading"
                    @click="configure"
                >Configure Stripe Account</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {countryChoices} from '@/graphql'
    import { stores } from '@/graphql'
    import {createConnectedAccount} from '@/graphql'
    export default {
        name: 'OnboardingDialog',
        props: ['value'],
        apollo: { countryChoices, stores },
        computed: {
            unconnectedStores () {
                return this.stores.filter(store => !store.connectedAccount)
            },
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            }
        },
        data: () => ({
            valid: null,
            loading: false,
            country: null,
            selectedStores: [],
            required: v => !!v || 'Please select an option'
        }),
        watch: {
            stores (stores) {
                this.selectedStores = stores
            }
        },
        methods: {
            configure () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: createConnectedAccount,
                    variables: {
                        country: this.country,
                        stores: this.selectedStores.map(store => store.hashId)
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL Error: ', '');
                    this.loading = false
                }).then(({ data }) => {
                    const { url } = data.createConnectedAccount;
                    window.location = url
                })
            }
        }
    }
</script>
