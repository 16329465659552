<template>
    <v-overlay :value="value" z-index="10000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</template>

<script>
    export default {
        name: 'AppLoader',
        props: ['value']
    }
</script>
