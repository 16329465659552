import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'


Vue.use(Vuetify);

export default new Vuetify({
      theme: {
    themes: {
      light: {
        primary: colors.teal.darken2,
        secondary: colors.blueGrey.darken4,
        warning: colors.deepOrange.accent2,
        error: colors.red.darken3,
        accent: colors.deepOrange.darken3
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },

});
