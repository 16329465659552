<template>
    <div>
        <v-snackbar :color="snackbarColor" v-model="snackbar">{{snackbarMessage}}</v-snackbar>
        <v-btn
            class="ml-2"
            color="primary"
            outlined
            rounded
            v-bind="{ ...$attrs, ...$props }"
            :loading="loading"
            @click="resendQuote"
        >Resend quote</v-btn>
    </div>
</template>

<script>
    import {resendQuote} from '@/graphql'
    export default {
        name: 'ResendQuote',
        props: ['order', 'disabled'],
        data: () => ({
            loading: false,
            snackbar: false,
            snackbarMessage: null,
            snackbarColor: null
        }),
        methods: {
            resendQuote () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: resendQuote,
                    variables: {
                        order: this.order.id
                    }
                }).then((result) => {
                    if (result) {
                        this.loading = false;
                        this.snackbar = true;
                        this.snackbarMessage = 'Quote sent to customer';
                        this.snackbarColor = 'success'
                    }
                }).catch((error) => {
                    this.snackbar = true;
                    this.snackbarMessage = error.message.replace('GraphQL error:', '');
                    this.snackbarColor = 'error';
                    this.loading = false
                })
            }
        }
    }
</script>
