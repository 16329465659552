<template>
    <v-dialog v-model="dialog" max-width="400">
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <template v-slot:activator="{ on }">
            <v-btn
                icon
                color="error"
                x-small
                v-on="on"
                :disabled="user.username === profile.user.username"
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
        <v-card flat>
            <nice-header>Really delete user?</nice-header>
            <v-card-text>
                You are about to delete this user - this action cannot be undone. Continue?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    rounded
                    color="error"
                    :loading="loading"
                    @click="deleteUser"
                >
                    Delete user
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {deleteUser} from '@/graphql'
    import { user } from '@/graphql'

    export default {
        name: 'DeleteUser',
        props: ['profile'],
        apollo: { user },
        data: () => ({
            loading: false,
            dialog: false,
            error: false,
            errorMessage: null
        }),
        methods: {
            deleteUser () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: deleteUser,
                    variables: {
                        user: this.profile.user.username
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then((result) => {
                    if (result) {
                        this.loading = false;
                        this.$emit('delete');
                        this.dialog = false
                    }
                })
            }
        }
    }
</script>
