<template>
    <modal
        width="600"
        btn-text="View deposit"
        title="Deposit status"
        v-model="dialog"
    >
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <v-card-text class="py-0 my-0">
            <v-alert  v-if="!order.deposit.paymentMethod" type="warning" outlined>
                The customer has not yet added card details for this deposit
            </v-alert>
            <v-alert v-else-if="order.status === 'PENDING' || order.status === 'CONFIRMED'" type="success" outlined>
                Customer has added card details and a card hold for the deposit amount will be created
                when the hire is started
            </v-alert>
            <v-alert v-else-if="order.deposit.status === 'Expired'" type="warning" outlined>
                The card hold for this deposit has expired, as it is older than 7 days. You can attempt to
                extend the deposit below
            </v-alert>
        </v-card-text>
        <v-card-text>
            <v-row>
                <v-col cols="6" class="py-0">
                    <v-list two-line class="py-0">
                        <list-item
                            :title="order.deposit.status"
                            subtitle="Deposit status"
                            icon="mdi-list-status"
                        ></list-item>
                    </v-list>
                </v-col>
                <v-col cols="6" class="py-0">
                    <v-list two-line class="py-0">
                        <list-item
                            :title="`${order.currency.symbol} ${order.deposit.amount}`"
                            subtitle="Deposit amount"
                            icon="mdi-cash"
                        ></list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn
                outlined
                rounded
                color="warning"
                :disabled="order.status !== 'CONFIRMED'"
                :loading="deleting"
                @click="cancelDeposit"
            >
                Cancel deposit request
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                outlined
                rounded
                color="info"
                :disabled="order.deposit.status !== 'Expired'"
                :loading="loading"
                @click="extendDeposit"
                class="mr-2"
            >
                Extend deposit
            </v-btn>
            <retain-deposit :order="order" @capture="handleCapture" :disabled="!canCapture"></retain-deposit>
        </v-card-actions>
    </modal>
</template>

<script>
    import {deleteDeposit, extendDeposit} from '@/graphql'
    export default {
        name: 'ViewDeposit',
        props: ['order'],
        data: () => ({
            dialog: false,
            loading: false,
            deleting: false,
            error: false,
            errorMessage: null,
        }),
        methods: {
            handleCapture () {
                this.$emit('capture')
            },
            extendDeposit () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: extendDeposit,
                    variables: {
                        order: this.order.hashId
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then((result) => {
                    if (result) {
                        this.loading = false;
                        this.dialog = false;
                        this.$emit('capture')
                    }
                })
            },
            cancelDeposit () {
                this.deleting = true;
                this.$apollo.mutate({
                    mutation: deleteDeposit,
                    variables: {
                        order: this.order.hashId
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.deleting = false
                }).then((result) => {
                    if (result) {
                        this.deleting = false;
                        this.dialog = false;
                        this.$emit('capture')
                    }
                })
            }
        },
        computed: {
            canCapture () {
                return ['IN_PROGRESS'].indexOf(this.order.status) > -1
                    && !!this.order.paymentMethod
                    && !this.order.deposit.amountCaptured
                    && !this.order.deposit.expired
            }
        }
    }
</script>
