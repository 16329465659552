<template>
    <v-navigation-drawer
        v-model="drawer"
        right
        width="50%"
        app
        temporary
    >
        <template v-slot:prepend>
            <nice-header>
                {{ $route.name }} Help
                <template v-slot:extra>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="drawer = !drawer">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </nice-header>
            <v-divider class="mx-5"></v-divider>
        </template>
        <v-card flat>
            <slot></slot>
        </v-card>
        <template v-slot:append>
            <v-footer>
                <v-card flat class="transparent">
                    <v-card-text>
                        If you have any queries about using this page, or any other part of Click Book Inflatables please contact us at
                        kyle@clickbookinflatables.com.
                    </v-card-text>
                </v-card>
            </v-footer>
        </template>

    </v-navigation-drawer>
</template>

<script>
    export default {
        name: 'HelpDrawer',
        props: ['value'],
        computed: {
            drawer: {
                get() {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>
