<template>
   <v-text-field
        v-bind="{ ...$attrs, ...$props }"
        rounded
        outlined
        v-model="time"
        :rules="rules"
   />
</template>

<script>
    import moment from 'moment'
    const errorMessage = 'Please enter a valid time using the 24H format, HH:MM'
    export default {
        name: 'TimePicker',
        props: ['value', 'label', 'dense'],
        data: () => ({
            rules: [
                v => moment(v, 'hh:mm').isValid() || errorMessage,
                v => !!v || errorMessage,
                v => !!v && !v.match(/[a-z]/i) || errorMessage,
                v => !!v && v.length === 5  || errorMessage
            ]
        }),
        computed: {
            time: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },

        }
    }
</script>
