<template>
    <v-form v-model="valid" ref="form">
        <v-row>
            <v-col cols="12" md="6" offset-sm="2">
                <v-file-input
                    :loading="loading"
                    show-size
                    counter
                    multiple
                    rounded
                    outlined
                    v-model="files"
                    accept="image/*"
                    :rules="rules"
                    label="Select files to upload"
                ></v-file-input>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn
                    outlined
                    x-large
                    rounded
                    color="primary"
                    block
                    :disabled="!valid"
                    @click="uploadFiles"
                    :loading="loading"
                >Upload</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import { user, createImage, upload } from '@/graphql'

    export default {
        name: 'FileUploader',
        props: ['productId'],
        data: () => ({
            files: [],
            valid: null,
            rules: [
                v => v.length > 0 || 'You must select at least one file'
            ],
            loading: false,
            error: false,
            errorMessage: null,
            uploadedFiles: {}
        }),
        apollo: { user },
        computed: {
            allUploaded () {
                var result = true;
                for (var i in this.uploadedFiles) {
                    var file = this.uploadedFiles[i];
                    if (!file) result = false
                }
                return result
            }
        },
        methods: {
            linkDocument (key, filename) {
                this.$apollo.mutate({
                    mutation: createImage,
                    variables: {
                        product: this.productId,
                        key
                    }
                }).then(() => {
                    this.uploadedFiles[filename] = true;
                    if (this.allUploaded) {
                        this.$refs.form.reset();
                        var self = this;
                        setTimeout(() => {
                            self.$emit('uploaded');
                        }, 500);
                        this.loading = false;
                        this.uploadedFiles = {}
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message;
                    this.loading = false
                })
            },
            async uploadFiles() {
                this.loading = true;
                this.uploadedFiles = Object.assign({}, ...this.files.map((file) => ({[file.name]: false})));
                for (var i in this.files) {
                    let file = this.files[i];
                    const {data} = await this.$apollo.mutate({
                        mutation: upload,
                        variables: {
                            product: this.productId,
                            filename: file.name
                        }
                    });
                    const { key, credentials, policy, signature, url, algorithm, date } = data.presignedUploadUrl;
                    var form = new FormData();

                    form.append('Content-Type', file.type);
                    form.append('key', key);
                    form.append('policy', policy);
                    form.append('X-Amz-Credential', credentials);
                    form.append('X-Amz-Signature', signature);
                    form.append('X-Amz-Algorithm', algorithm);
                    form.append('X-Amz-Date', date);
                    form.append("file", file);

                    var xhr = new XMLHttpRequest();
                    // xhr.upload.addEventListener("progress", uploadProgress, false);
                    xhr.addEventListener("load", this.linkDocument(key, file.name), false);
                    // xhr.addEventListener("error", uploadFailed, false);
                    // xhr.addEventListener("abort", uploadCanceled, false);
                    xhr.open('POST', url);
                    xhr.send(form);
                }
            }
        }
    }
</script>
