import AppBar from '@/components/AppBar';
import NavigationDrawer from '@/components/NavigationDrawer';
import TextField from '@/components/TextField';
import LoginScreen from '@/components/LoginScreen';
import Btn from '@/components/Btn';
import ArchivePlan from '@/components/ArchivePlan';
import ArchiveProduct from '@/components/ArchiveProduct';
import Modal from '@/components/Modal';
import Autocomplete from '@/components/Autocomplete';
import PricingPlanForm from '@/components/PricingPlanForm';
import AuthWrapper from '@/components/AuthWrapper';
import FileUploader from '@/components/FileUploader';
import RegisterScreen from '@/components/RegisterScreen';
import ProductBookingTable from '@/components/ProductBookingTable';
import NiceHeader from '@/components/NiceHeader';
import UpdateOrderButton from '@/components/UpdateOrderButton';
import CancelOrderButton from '@/components/CancelOrderButton';
import SKUButton from '@/components/SKUButton';
import ListItem from '@/components/ListItem';
import TimePicker from '@/components/TimePicker';
import OnboardingDialog from '@/components/OnboardingDialog';
import IssueRefundButton from '@/components/IssueRefundButton';
import RouteItem from '@/components/RouteItem';
import AddAssets from '@/components/AddAssets';
import EditAsset from '@/components/EditAsset';
import ArchiveAsset from '@/components/ArchiveAsset';
import OneOffClosure from '@/components/OneOffClosure';
import DeleteClosure from '@/components/DeleteClosure';
import HelpDrawer from '@/components/HelpDrawer';
import DashboardHelp from '@/components/DashboardHelp';
import InventoryHelp from '@/components/InventoryHelp';
import ProductDetailsHelp from '@/components/ProductDetailsHelp';
import OrdersHelp from '@/components/OrdersHelp';
import OrderViewHelp from '@/components/OrderViewHelp';
import AccountViewHelp from '@/components/AccountViewHelp';
import StoreHelp from '@/components/StoreHelp';
import EditUser from '@/components/EditUser';
import DeleteUser from '@/components/DeleteUser';
import NewUserForm from '@/components/NewUserForm';
import ResetPassword from '@/components/ResetPassword';
import AdminWrapper from '@/components/AdminWrapper';
import AppLoader from '@/components/AppLoader';
import RequestDeposit from '@/components/RequestDeposit';
import ViewDeposit from '@/components/ViewDeposit';
import RetainDeposit from '@/components/RetainDeposit';
import CompleteHireButton from '@/components/CompleteHireButton';
import EnquiryDialog from '@/components/EnquiryDialog';
import DatePicker from '@/components/DatePicker';
import ResendQuote from '@/components/ResendQuote';
import NewCustomerDialog from '@/components/NewCustomerDialog';
import UploadLegalNotice from '@/components/UploadLegalNotice';
import DeleteDocument from '@/components/DeleteDocument';
import CustomDomainDialog from '@/components/CustomDomainDialog';
import RatingTable from '@/components/RatingTable';


const GlobalComponents = {
  install (Vue) {
    Vue.component(AppBar.name, AppBar);
    Vue.component(NavigationDrawer.name, NavigationDrawer);
    Vue.component(TextField.name, TextField);
    Vue.component(LoginScreen.name, LoginScreen);
    Vue.component(Btn.name, Btn);
    Vue.component(ArchivePlan.name, ArchivePlan);
    Vue.component(Modal.name, Modal);
    Vue.component(Autocomplete.name, Autocomplete);
    Vue.component(PricingPlanForm.name, PricingPlanForm);
    Vue.component(AuthWrapper.name, AuthWrapper);
    Vue.component(RegisterScreen.name, RegisterScreen);
    Vue.component(FileUploader.name, FileUploader);
    Vue.component(ProductBookingTable.name, ProductBookingTable);
    Vue.component(NiceHeader.name, NiceHeader);
    Vue.component(CancelOrderButton.name, CancelOrderButton);
    Vue.component(UpdateOrderButton.name, UpdateOrderButton);
    Vue.component(SKUButton.name, SKUButton);
    Vue.component(ListItem.name, ListItem);
    Vue.component(TimePicker.name, TimePicker);
    Vue.component(OnboardingDialog.name, OnboardingDialog);
    Vue.component(IssueRefundButton.name, IssueRefundButton);
    Vue.component(RouteItem.name, RouteItem);
    Vue.component(AddAssets.name, AddAssets);
    Vue.component(EditAsset.name, EditAsset);
    Vue.component(ArchiveProduct.name, ArchiveProduct);
    Vue.component(ArchiveAsset.name, ArchiveAsset);
    Vue.component(OneOffClosure.name, OneOffClosure);
    Vue.component(DeleteClosure.name, DeleteClosure);
    Vue.component(HelpDrawer.name, HelpDrawer);
    Vue.component(DashboardHelp.name, DashboardHelp);
    Vue.component(InventoryHelp.name, InventoryHelp);
    Vue.component(ProductDetailsHelp.name, ProductDetailsHelp);
    Vue.component(OrdersHelp.name, OrdersHelp);
    Vue.component(OrderViewHelp.name, OrderViewHelp);
    Vue.component(AccountViewHelp.name, AccountViewHelp);
    Vue.component(StoreHelp.name, StoreHelp);
    Vue.component(EditUser.name, EditUser);
    Vue.component(NewUserForm.name, NewUserForm);
    Vue.component(DeleteUser.name, DeleteUser);
    Vue.component(ResetPassword.name, ResetPassword);
    Vue.component(AdminWrapper.name, AdminWrapper);
    Vue.component(AppLoader.name, AppLoader);
    Vue.component(RequestDeposit.name, RequestDeposit);
    Vue.component(ViewDeposit.name, ViewDeposit);
    Vue.component(RetainDeposit.name, RetainDeposit);
    Vue.component(CompleteHireButton.name, CompleteHireButton);
    Vue.component(EnquiryDialog.name, EnquiryDialog);
    Vue.component(DatePicker.name, DatePicker);
    Vue.component(ResendQuote.name, ResendQuote);
    Vue.component(NewCustomerDialog.name, NewCustomerDialog);
    Vue.component(UploadLegalNotice.name, UploadLegalNotice);
    Vue.component(DeleteDocument.name, DeleteDocument);
    Vue.component(CustomDomainDialog.name, CustomDomainDialog);
    Vue.component(RatingTable.name, RatingTable);
  }
};

export default GlobalComponents
