<template>
    <div>
        <v-card-text>
            The inventory shows you all the products that are currently available for hire through your
            Rentify store. You can <strong>add, view edit</strong> and <strong>delete</strong>
            products from your inventory from this view
        </v-card-text>

        <v-subheader class="title">Adding a new product</v-subheader>
        <v-card-text>
            To add a new product to your inventory, simply click on the <strong>Add new product</strong>
            button in the top right hand corner or this view. You'll first be prompted to provide a
            <strong>name</strong> and <strong>category</strong> for this product. The product name is the first
            thing your customer will see for this product in your store - pick a name that makes it clear to
            your customers what it is they are renting. The category is used for grouping several products
            together. Correct categorization of your product helps your customers find what they are looking
            for in your store. As an example, if you have a bike hire shop, you might have a product called
            <strong>Road bike</strong> in a category called <strong>Bikes</strong>, or a product called
            <strong>Helmet</strong> in a category called <strong>Accessories</strong>. Whenever you add a new
            category, it will be selectable from the drop down list for all new products you add in future.
        </v-card-text>

        <v-card-text>
            Once you've decided on an appropriate product name and category, you will need to provide some
            <strong>variants</strong>. In other words, you'll need to tell Rentify how many of each type
            of product you have available. This section allows you to specify any combination of
            product tags and how many of each combination of these values. All three of these labels is
            optional, and can be left blank if required. However, remember that filling out these details
            accurately will help your customer find exactly what they need in your store.
        </v-card-text>

        <v-card-text>
            Once you've filled out all the fields properly, click the <strong>Create product</strong> button
            to add it to your inventory. You'll be able to add product images, a product description, and
            pricing plans at this stage.
        </v-card-text>

        <v-card-text>
            Remember that you don't have to add all product variants at once - you can add, edit and remove
            any individual items for a product later on. You'll need to add at least one pricing plan to your
            product to make it available in your Rentify store.
        </v-card-text>

        <v-subheader class="title">Viewing and Editing existing products</v-subheader>
        <v-card-text>
            You can view and edit the details of any product in your inventory at any time by clicking on the
            small pencil icon,
            <v-icon color="primary">mdi-pencil</v-icon>
            , next to its name.
        </v-card-text>

        <v-subheader class="title">Deleting products</v-subheader>
        <v-card-text>
            You can delete a product by clicking on the red bin icon,
            <v-icon color="error">mdi-delete</v-icon>
            ,
            next to its name. Deleting a product is permanent and will prevent your customer from being able to
            see and book this product in your store. If you ever delete a product for which pending orders
            exist, you'll need to manually resolve the status of these orders.
        </v-card-text>
    </div>
</template>


<script>
    export default {
        name: 'InventoryHelp'
    }
</script>
