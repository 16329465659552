export const createUser = require('./authentication/createUser.gql')
export const createSumoUser = require('./authentication/createSumoUser.gql')
export const linkSumoUser = require('./authentication/linkSumoUser.gql')
export const getAdminCheckout = require('./authentication/getAdminCheckout.gql')
export const createConnectedAccount = require('./authentication/createConnectedAccount.gql')
export const createSubuser = require('./authentication/createSubuser.gql')
export const editUser = require('./authentication/editUser.gql')
export const setPassword = require('./authentication/setPassword.gql')
export const deleteUser = require('./authentication/deleteUser.gql')
export const resetPassword = require('./authentication/resetPassword.gql')
export const user = require('./authentication/user.gql')
export const subscription = require('./authentication/subscription.gql')
export const billingPortalUrl = require('./authentication/billingPortalUrl.gql')
export const countryChoices = require('./authentication/countryChoices.gql')
export const users = require('./authentication/users.gql')
export const resetEmail = require('./authentication/resetEmail.gql')
export const login = require('./authentication/login.gql')

export const stores = require('./core/stores.gql')
export const archivePlan = require('./core/archivePlan.gql')
export const accountData = require('./core/accountData.gql')
export const updateOrder = require('./core/updateOrder.gql')
export const product = require('./core/product.gql')
export const addAssets = require('./core/addAssets.gql')
export const addClosure = require('./core/addClosure.gql')
export const archiveAsset = require('./core/archiveAsset.gql')
export const archiveProduct = require('./core/archiveProduct.gql')
export const bookings = require('./core/bookings.gql')
export const calendar = require('./core/calendar.gql')
export const captureDeposit = require('./core/captureDeposit.gql')
export const clearNotification = require('./core/clearNotification.gql')
export const createCustomer = require('./core/createCustomer.gql')
export const createDeposit = require('./core/createDeposit.gql')
export const createDocument = require('./core/createDocument.gql')
export const createImage = require('./core/createImage.gql')
export const adminProducts = require('./core/adminProducts.gql')
export const createCatalog = require('./core/createCatalog.gql')
export const catalogPresignedUploadUrl = require('./core/catalogPresignedUploadUrl.gql')
export const createStoreImage = require('./core/createStoreImage.gql')
export const deleteStoreImage = require('./core/deleteStoreImage.gql')
export const createTag = require('./core/createTag.gql')
export const editTag = require('./core/editTag.gql')
export const deleteTag = require('./core/deleteTag.gql')
export const editLabels = require('./core/editLabels.gql')
export const createCoupon = require('./core/createCoupon.gql')
export const deleteCoupon = require('./core/deleteCoupon.gql')
export const markOrderPaid = require('./core/markOrderPaid.gql')
export const editTheme = require('./core/editTheme.gql')
export const addressSearch = require('./core/addressSearch.gql')
export const createManualOrder = require('./core/createManualOrder.gql')
export const createPlan = require('./core/createPlan.gql')
export const createProduct = require('./core/createProduct.gql')
export const currencies = require('./core/currencies.gql')
export const customers = require('./core/customers.gql')
export const deleteClosure = require('./core/deleteClosure.gql')
export const deleteDeposit = require('./core/deleteDeposit.gql')
export const deleteDocument = require('./core/deleteDocument.gql')
export const deleteImage = require('./core/deleteImage.gql')
export const editProduct = require('./core/editProduct.gql')
export const editAsset = require('./core/editAsset.gql')
export const editStore = require('./core/editStore.gql')
export const enquiries = require('./core/enquiries.gql')
export const extendDeposit = require('./core/extendDeposit.gql')
export const issueRefund = require('./core/issueRefund.gql')
export const notifications = require('./core/notifications.gql')
export const orderDetails = require('./core/orderDetails.gql')
export const orders = require('./core/orders.gql')
export const resendQuote = require('./core/resendQuote.gql')
export const skus = require('./core/skus.gql')
export const upload = require('./core/upload.gql')
export const uploadDocument = require('./core/uploadDocument.gql')
export const uploadStoreImage = require('./core/uploadStoreImage.gql')
export const createDeliveryFee = require('./core/createDeliveryFee.gql')
export const deleteDeliveryFee = require('./core/deleteDeliveryFee.gql')

export default {
    currencies,
    uploadStoreImage,
    upload,
    uploadDocument,
    skus,
    resendQuote,
    orderDetails,
    orders,
    notifications,
    issueRefund,
    enquiries,
    extendDeposit,
    editStore,
    editAsset,
    deleteImage,
    editProduct,
    login,
    deleteDocument,
    deleteDeposit,
    deleteClosure,
    customers,
    createProduct,
    createPlan,
    createManualOrder,
    stores,
    adminProducts,
    createCatalog,
    catalogPresignedUploadUrl,
    createStoreImage,
    deleteStoreImage,
    createTag,
    editTag,
    deleteTag,
    editLabels,
    createCoupon,
    deleteCoupon,
    markOrderPaid,
    editTheme,
    addressSearch,
    createUser,
    createSumoUser,
    linkSumoUser,
    createConnectedAccount,
    getAdminCheckout,
    createSubuser,
    editUser,
    deleteUser,
    setPassword,
    resetPassword,
    user,
    users,
    billingPortalUrl,
    countryChoices,
    subscription,
    resetEmail,
    accountData,
    updateOrder,
    product,
    addAssets,
    addClosure,
    archiveAsset,
    archivePlan,
    archiveProduct,
    bookings,
    calendar,
    captureDeposit,
    clearNotification,
    createCustomer,
    createDeposit,
    createDocument,
    createImage,
    createDeliveryFee,
    deleteDeliveryFee
}
