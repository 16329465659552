<template>
    <div>
        <v-card-text>
            The account view lets you upgrade to a paid account, or modify or cancel an existing paid subscription.
        </v-card-text>
        <v-card-text>
            Subscriptions in Rentify are managed securely in <a href="https://stripe.com/" target="_blank" style="text-decoration: none">Stripe</a>
        </v-card-text>
    </div>
</template>

<script>
    export default {
        name: 'AccountViewHelp'
    }
</script>
