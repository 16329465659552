<template>
    <span>
        <v-snackbar color="error" v-model="error">{{ errorMessage }}</v-snackbar>
        <v-dialog width="600" v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        v-on="on"
                        v-bind="attrs"
                        outlined
                        rounded
                        :disabled="disabled"
                        color="error"
                        class="mx-2"
                >
                    Cancel order
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="primary-title justify-center mb-2">
                    Really cancel order?
                </v-card-title>
                <v-card-text>
                    You are about to cancel this order. This action cannot be undone, and the customer will be
                    notified by email. Are you sure you want to proceed?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        rounded
                        color="error"
                        :loading="loading"
                        @click="cancelOrder"
                    >
                        Cancel booking
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
    import { updateOrder } from '@/graphql'

    export default {
        name: 'CancelOrderButton',
        props: ['order', 'disabled'],
        data: () => ({
            dialog: false,
            loading: false,
            error: false,
            errorMessage: null
        }),
        methods: {
            cancelOrder() {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: updateOrder,
                    variables: {
                        pk: this.order.id,
                        newStatus: 'CANCELLED'
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message;
                    this.loading = false
                }).then(() => {
                    this.$emit('cancelled');
                    this.dialog = false
                    this.loading = false
                })
            }
        }

    }
</script>
