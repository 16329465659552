<template>
    <v-dialog v-model="dialog" max-width="600">
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <template v-slot:activator="{ on }">
            <v-btn
                    icon
                    x-small
                    color="error"
                    v-on="on"
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
        <v-card flat>
            <v-card-title>
                Really delete product?
            </v-card-title>
            <v-alert v-if="product.hasPendingOrders" outlined type="warning" class="mx-5" dense>
                This product has pending or confirmed orders against it. Make sure you know what you're doing
            </v-alert>

            <v-card-text>
                You are about to delete this product. This action cannot be undone. Are you sure you want to
                continue?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        rounded
                        outlined
                        color="error"
                        :loading="loading"
                        @click="archiveProduct"
                >
                    Delete product
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { archiveProduct } from '@/graphql'

    export default {
        name: 'ArchiveProduct',
        props: ['product'],
        data: () => ({
            dialog: null,
            loading: false,
            error: false,
            errorMessage: null
        }),
        methods: {
            archiveProduct() {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: archiveProduct,
                    variables: {
                        product: this.product.id
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then((result) => {
                    if (result) {
                        this.loading = false;
                        this.$emit('archived')
                    }
                })
            }
        }
    }
</script>
