<template>
    <v-dialog v-model="dialog" max-width="600">
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <template v-slot:activator="{ on }">
            <v-btn
                color="primary"
                rounded
                outlined
                v-on="on"
                :disabled="disabled"
            >
                Request security deposit
            </v-btn>
        </template>
        <v-card flat>
            <nice-header>
                Request security deposit
            </nice-header>
            <v-card-text>
                Rentify lets you request a security deposit from your customers on their orders. If you request a
                security deposit for this order, your customer will receive an email asking them to provide card
                details for this. When you click the <strong>Start order</strong> button, this will place a temporary
                hold on their card, for a maximum of up to 7 days. Due to banking laws, we are unable to place a longer
                hold than this, but it is possible to attempt to extend the holding period. When you click the
                <strong>Complete order</strong> button when your assets are returned safely. However, if the assets
                are not returned, or are returned damaged, you have the ability to retain the security deposit, either
                partially or in full.
            </v-card-text>
            <v-form v-model="valid">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                outlined
                                rounded
                                label="Deposit amount"
                                :prefix="order.currency.symbol"
                                :rules="rules"
                                v-model="amount"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        rounded
                        outlined
                        color="primary"
                        :loading="loading"
                        :disabled="!valid"
                        @click="requestDeposit"
                    >
                        Request deposit
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    import {createDeposit} from '@/graphql'

    export default {
        name: 'RequestDeposit',
        props: ['order', 'disabled'],
        data: () => ({
            dialog: false,
            loading: false,
            error: false,
            errorMessage: null,
            valid: null,
            amount: 0,
            rules: [
                v => !!v || 'Please enter a value greater than zero',
                v => !isNaN(v) || 'Invalid value',
                v => v > 0 || 'Please enter a value greater than zero'
            ]
        }),
        methods: {
            requestDeposit () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: createDeposit,
                    variables: {
                        order: this.order.hashId,
                        amount: this.amount
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then((result) => {
                    if (result) {
                        this.loading = false;
                        this.dialog = false;
                        this.$emit('create')
                    }
                })
            }
        }
    }
</script>
