<template>
  <v-container v-if="product">
    <nice-header>Product reviews for {{product.name}}</nice-header>
      <v-data-table
        v-if="(subscription && subscription.plan.product.name === 'Advanced') || accountData.ltdCustomer"
        :headers="headers"
        :items="product.reviews"
        :items-per-page="15"
      >
        <template v-slot:item.starRating="{ item }">
          <v-rating readonly :value="item.starRating"/>
        </template>
      </v-data-table>
      <v-card-text v-else>
        Product reviews are an Advanced feature. To enable it, you must <router-link to="/account">Upgrade your account</router-link>
      </v-card-text>
  </v-container>
</template>

<script>
import { accountData } from '@/graphql'
import { subscription } from '@/graphql'

export default {
  name: 'RatingTable',
  props: ['product'],
  apollo: { accountData, subscription },
  data: () => ({
    headers: [
      { text : 'Review', value: 'text' },
      { text : 'Rating', value: 'starRating' },
    ]
  })
}
</script>