<template>
    <v-dialog max-width="800" v-model="dialog">
        <template v-slot:activator="{ on }">
            <v-btn
                    outlined
                    rounded
                    color="primary"
                    v-on="on"
                    :disabled="disabled"
            >
                View SKUs assigned to this order
            </v-btn>
        </template>
        <v-card flat>
            <nice-header>SKUs for order {{ order.hashId }}</nice-header>
            <v-container>
            <v-simple-table>
                <thead>
                <tr>
                    <th class="text-left title">SKU</th>
                    <th class="text-left title">{{store.labelOne}}</th>
                    <th class="text-left title">{{store.labelTwo}}</th>
                    <th class="text-left title">{{store.labelThree}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in skus" :key="item.sku">
                    <td>{{ item.sku }}</td>
                    <td>{{ item.size }}</td>
                    <td>{{ item.brand }}</td>
                    <td>{{ item.gender}}</td>

                </tr>
                </tbody>
            </v-simple-table>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapState } from 'vuex'
    import { stores, skus } from '@/graphql'
    export default {
        name: 'SkuButton',
        props: ['order', 'disabled'],
        computed: {
            ...mapState(['selectedStore']),
            store () {
                if (this.selectedStore) {
                    return this.stores.find(store => store.hashId === this.selectedStore)
                }
                return null
            }
        },
        apollo: {
            stores,
            skus: {
                query: skus,
                variables () {
                    return {
                        order: this.order.id
                    }
                }
            }
        }

    }
</script>
